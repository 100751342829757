<script setup lang="ts">
  import type { QuotedPlanVm } from '~/models/quoting/QuotedPlan'
  import { PlanType, SnpType } from '~/generated/api-clients-generated'

  const props = defineProps<{
    id: string
    quote: QuotedPlanVm
  }>()

  const { getId } = Id.use(props.id)

  const { getComponentContent } = Cms.use()

  const { maybeDo } = ActionGuard.use()

  const { content } = await getComponentContent('QuoteCardSectionPlanScoreCallout')

  const { show } = DialogController.use()

  const estYearlyDrugCosts = computed(() => {
    if (props.quote.details.year === 2025 && props.quote.drugCoverage.allCovered) {
      return _clamp(props.quote.drugCost, 2000)
    }
    return props.quote.drugCost
  })

  const { getRange } = ExpectedCostsRange.use()

  const isDsnp = computed(() => props.quote.snpType === SnpType.DualEligible)

  const costRange = computed(() =>
    props.quote.type === PlanType.MAPD ? getRange(props.quote) : []
  )
</script>

<template>
  <section
    class="plan-fit-callout-container flex flex-col items-center justify-center rounded-3xl bg-sky-100 py-3"
  >
    <div class="flex items-center gap-x-2" v-if="quote.type === PlanType.MAPD">
      <HpIcon name="healthpilot-icon-simple" class="mb-2 scale-125" />
      <h2 class="mb-1 text-xl font-semibold">
        {{ content.ext.title }}
      </h2>
    </div>
    <HpAnchor
      v-if="quote.type === PlanType.MAPD"
      class="cursor-pointer text-primary-900 no-underline"
      @click.prevent="show(getId(`score-calc-sidebar`))"
    >
      <QuotePlanScoreV2
        :score="quote.score"
        band-thickness="thin"
        size="large"
        class="mb-2"
        @click="show(getId(`score-calc-sidebar`))"
      />
      <QuoteCardScoreSidebar :id="getId(`score-calc-sidebar`)" :quote="quote" />
    </HpAnchor>
    <dl class="flex w-full max-w-md flex-col gap-y-4 px-3">
      <div class="flex w-full flex-col">
        <div class="flex w-full flex-col justify-between sm:flex-row">
          <dt class="font-bold">
            <PxTooltip name="DrugCostPlusPremiumCost" text="Yearly Premium + Drug Costs" />
          </dt>
          <dd class="text-xl font-bold">
            {{ formatUsd(estYearlyDrugCosts + quote.recommendation.annualPremium) }}
          </dd>
        </div>
        <div class="flex w-full flex-col justify-between sm:flex-row sm:indent-3">
          <dt class="text-base">
            Plan Premium ({{ formatUsd(quote.recommendation.premium) }} x 12 months)
          </dt>
          <dd>{{ formatUsd(quote.recommendation.annualPremium) }}</dd>
        </div>
        <div class="flex w-full flex-col justify-between sm:flex-row sm:indent-3">
          <dt class="flex flex-col">
            <div class="text-base">Yearly Drug Costs (estimated)</div>
            <HpAnchor
              class="hidden text-base font-semibold underline-offset-2 sm:block"
              v-if="quote.drugCoverage.count > 0"
              @click.prevent="show(getId('drug-monthly-breakdown-sidebar'))"
              >See Breakdown</HpAnchor
            >
          </dt>
          <dd v-if="quote.drugCoverage.count > 0" class="flex flex-col">
            {{ formatUsd(estYearlyDrugCosts) }}
            <HpAnchor
              class="text-base font-semibold underline-offset-2 sm:hidden"
              v-if="quote.drugCoverage.count > 0"
              @click.prevent="show(getId('drug-monthly-breakdown-sidebar'))"
              >See Breakdown</HpAnchor
            >
          </dd>
          <dd v-else>
            <HpAnchor
              class="flex gap-x-0 indent-0 text-base font-semibold underline-offset-2"
              @click.prevent="() => maybeDo(() => show('update-drugs-pharmacy-sidebar'))"
            >
              <HpIcon name="add" class="scale-[75%]" />
              <span>Add Drugs</span>
            </HpAnchor>
          </dd>
        </div>
        <div class="sm:indent-3" v-if="quote.drugCoverage.count > 0">
          <HpAnchor
            class="flex gap-x-0 text-base font-semibold underline-offset-2"
            @click.prevent="() => maybeDo(() => show('update-drugs-pharmacy-sidebar'))"
          >
            <span>Edit Drugs</span>
          </HpAnchor>
        </div>
      </div>
      <div
        class="flex w-full flex-col justify-between sm:flex-row"
        v-if="quote.type === PlanType.MAPD"
      >
        <dt class="flex flex-col">
          <span class="font-bold leading-snug">Projected Health Costs</span>
          <HpAnchor
            @click.prevent="show(getId('cost-range-sidebar'))"
            class="hidden text-base font-semibold underline-offset-2 sm:block"
            v-if="!isDsnp"
          >
            Learn More
          </HpAnchor>
        </dt>
        <dd class="flex flex-col text-xl">
          <template v-if="!isDsnp"
            >{{ formatUsd(costRange[0]) }}-{{ formatUsd(costRange[1]) }}</template
          >
          <template v-else>
            <HpAnchor
              @click.prevent="show(getId('cost-range-sidebar'))"
              class="text-base font-semibold underline-offset-2"
            >
              Learn More
            </HpAnchor>
          </template>
          <HpAnchor
            @click.prevent="show(getId('cost-range-sidebar'))"
            class="text-base font-semibold underline-offset-2 sm:hidden"
            v-if="!isDsnp"
          >
            Learn More
          </HpAnchor>
        </dd>
      </div>
      <div
        class="flex w-full flex-col justify-between sm:flex-row"
        v-if="quote.type === PlanType.MAPD"
      >
        <PxCollapsible
          :id="getId('doctor-coverage')"
          class="preview-providers"
          v-if="quote.providerCoverage.count > 0"
        >
          <template #header>
            <dt class="providers-header">
              <PxTooltip name="DoctorsInNetwork" text="Doctors In-Network" />
            </dt>
            <dl class="font-bold">
              {{ quote.providerCoverage.inNetworkCount }} of
              {{ quote.providerCoverage.count }} Covered
            </dl>
          </template>
          <ul class="flex w-full flex-col gap-y-2 px-2">
            <li
              v-for="dr in quote.providerCoverage.providers"
              :key="dr.npi!"
              class="flex w-full items-start justify-between text-base"
            >
              <div>
                {{ dr.name }}
              </div>
              <div class="flex w-1/2 items-center justify-end gap-x-1 text-nowrap sm:w-1/3">
                <HpIcon v-if="dr.inNetwork" name="check" class="scale-75 text-success" />
                <HpIcon v-else class="text-danger-dark" name="no" />
                {{ dr.inNetwork ? 'In Network' : 'Out of Network' }}
              </div>
            </li>
          </ul>
          <HpAnchor
            class="flex items-center text-base underline-offset-2 sm:indent-2"
            @click.prevent="() => show('update-doctors-sidebar')"
          >
            <span class="font-semibold text-primary">Edit Doctors</span>
          </HpAnchor>
        </PxCollapsible>
        <template v-else>
          <div class="providers-header">
            <PxTooltip name="DoctorsInNetwork" text="Doctors In-Network" />
          </div>
          <HpAnchor
            class="flex items-center text-base underline-offset-2"
            @click.prevent="() => show('update-doctors-sidebar')"
          >
            <HpIcon name="add" class="scale-75" />
            <span class="font-semibold text-primary">Add Doctors</span>
          </HpAnchor>
        </template>
      </div>
      <div
        v-if="quote.type === PlanType.PDP"
        class="flex flex-col items-start justify-between sm:flex-row sm:items-center"
      >
        <dt class="font-bold">
          <PxTooltip name="DrugDeductible" />
        </dt>
        <dd class="text-xl font-bold">
          {{ formatUsd(quote.details.drugDeductible) }}
        </dd>
      </div>
    </dl>
  </section>
  <DetailsDrugMonthlyBreakdownSidebar
    :id="getId('drug-monthly-breakdown-sidebar')"
    :quote="quote"
  />
  <QuoteCostRangeSidebar
    :min="costRange[0]"
    :max="costRange[1]"
    :id="getId('cost-range-sidebar')"
    :is-dsnp="isDsnp"
  />
</template>

<style scoped>
  :deep(.score-value.large) {
    @apply text-5xl;
  }

  .providers-header :deep(.tooltip) {
    @apply font-bold;
  }

  /*
    Container queries would be a good way to position the collapsible toggle absolutely if
    the containing element is wide enough to support that. But right now they appear to break
    our tooltip positioning, so this attempt is commented out. 
  */

  /* .plan-fit-callout-container {
    container-type: inline-size;
    container-name: fit-callout;
  }*/

  .preview-providers :deep(.collapsible-toggle) {
    @apply !relative !right-0 sm:!absolute sm:!-right-7;

    @media (min-width: 1024px) and (max-width: 1151px) {
      @apply !relative !right-0;
    }

    /*@container fit-callout (width > 30rem) {
      @apply !absolute !-right-7;
    }*/
  }

  .preview-providers :deep(.collapsible-title) {
    @apply sm:!flex-row;
  }
</style>
