<script setup lang="ts">
  import type { QuotedPlanVm } from '~/models/quoting/QuotedPlan'
  import { EngineStore } from '~/stores/engine'
  import {
    type BenefitAmount,
    PlanBenefitCostType,
    PlanType
  } from '~/generated/api-clients-generated'

  const props = defineProps<{
    quote: QuotedPlanVm
    id: string
  }>()

  const isMapd = computed(() => props.quote.type === PlanType.MAPD)

  const { getId } = Id.use(props.id)

  const { track } = Analytics.use()

  const onToggle = (open) => {
    track('OtherCostsAndBenefitsToggled', {
      action: open ? 'opened' : 'closed'
    })
  }
</script>

<template>
  <section class="other-costs-and-benefits flex w-full">
    <PxCollapsible :id="getId('collapsible')" :on-toggle class="other-costs-collapsible">
      <template #header="{ open }">
        <div class="flex w-full items-center justify-between">
          <h2 class="text-xl">Other Costs & Benefits</h2>
          <span class="hidden cursor-pointer font-semibold text-primary sm:block">{{
            open ? 'See Less' : 'See More'
          }}</span>
        </div>
      </template>
      <dl class="content-section-data">
        <div
          class="flex w-full justify-between"
          v-for="[label, value, tooltip] in [
          ['PCP Copay', quote.details.providerBenefits?.doctorCopay, 'DoctorVisits'],
          ['Specialist Copay', quote.details.providerBenefits?.specialistCopay, 'SpecialistVisit'],
          ['Max Out-of-Pocket', { amount: quote.details.maxOutOfPocket, amountType: PlanBenefitCostType.Copay}, 'MaxOutOfPocket'],
          ['Yearly Medical Deductible', { amount: quote.details.medicalDeductible, amountType: PlanBenefitCostType.Copay}, 'MedicalDeductible'],
        ] as [string, BenefitAmount, string][]"
        >
          <dt>
            <PxTooltip :name="tooltip" :text="label" />
          </dt>
          <dd class="text-2xl font-bold">
            {{
              value.amountType === PlanBenefitCostType.Copay
                ? formatUsd(value.amount)
                : formatUsd(value.amount)
            }}
          </dd>
        </div>
      </dl>
    </PxCollapsible>
  </section>
</template>

<style scoped>
  .other-costs-and-benefits :deep(.collapsible-header) {
    @apply rounded-heading bg-gray-200 p-2.5;
  }

  .other-costs-and-benefits :deep(.collapsible-toggle) {
    @apply !relative !inset-0;
  }

  .content-section-data {
    @apply !pl-5 !pr-8;

    .content-row {
      dd {
        @apply !text-2xl;
      }
    }
  }
</style>
