<script setup lang="ts">
  import { QuotedPlanVm } from '~/models/quoting/QuotedPlan'
  import type { PlanBannerConfig } from '~/composables/PlanBanners'
  import QuoteCardHeader from '~/components/quote/card/QuoteCardHeader.vue'
  import QuoteCardFooter from '~/components/quote/card/QuoteCardFooter.vue'
  import QuoteCardSectionPlanFitCallout from '~/components/quote/card/section/QuoteCardSectionPlanFitCallout.vue'
  import QuoteCardSectionOtherCostsAndBenefits from '~/components/quote/card/section/QuoteCardSectionOtherCostsAndBenefits.vue'

  const props = defineProps<{
    quote: QuotedPlanVm
    id?: string
    banner?: Partial<PlanBannerConfig & { text: string }> | null | undefined
  }>()

  const { getId } = Id.use(props.id ?? props.quote.medicareId)
</script>

<template>
  <QuoteCardBase :id="getId()" :quote="quote" class="highlighting-plan-fit">
    <template #header>
      <QuoteCardHeaderCentered :quote="quote" :banner="banner" :id="getId('header')" />
    </template>
    <template #default="{ showPlanTags, isMapd }">
      <QuoteCardSectionPlanFitCallout :id="getId('plan-score-callout')" :quote="quote" />
      <QuoteCardSectionPlanTagsBoxed
        :quote="quote"
        :id="getId(`plan-tags-section`)"
        v-if="showPlanTags"
      />
      <QuoteCardSectionBenefitButtons
        :quote="quote"
        v-if="isMapd"
        :id="getId('benefitButtons')"
        hide-header
      />
      <QuoteCardSectionOtherCostsAndBenefits
        :quote="quote"
        v-if="isMapd"
        :id="getId('other-costs-and-benefits-section')"
      />
      <QuoteCardSectionActionsButtons :quote="quote" />
    </template>
    <template #footer>
      <QuoteCardFooter :quote="quote" />
    </template>
  </QuoteCardBase>
</template>

<style></style>
