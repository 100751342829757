<script setup lang="ts">
  import type { QuotedPlanVm } from '~/models/quoting/QuotedPlan'

  const props = defineProps<{
    quote: QuotedPlanVm
  }>()

  const { getQuoteCardEventProps } = QuoteAnalytics.use()
  const { push } = AppRouter.use()

  const { aepException, aepNoticeText } = await CarrierAepExceptions.use(
    props.quote.details.carrierKey!,
    props.quote.details.carrierName!
  )
</script>

<template>
  <div
    v-if="aepException"
    class="flex w-full cursor-default justify-center rounded-heading bg-[#D9DCDD] px-4 py-2 text-xl font-semibold text-primary-900"
  >
    {{ aepNoticeText }}
  </div>
  <div class="quote-actions" v-else>
    <HpButton variant="secondary" @click.prevent="push(`/details/${quote.medicareId}`)"
      >See Plan Details
    </HpButton>
    <PxApplyButton
      :medicare-id="quote.medicareId"
      :plan-type="quote.type"
      :plan-year="quote.details.year!"
      :event-data="getQuoteCardEventProps('apply', quote)"
    />
  </div>
</template>

<style scoped>
  .quote-actions {
    @apply flex flex-col items-center gap-x-2 gap-y-2 sm:flex-row sm:justify-between;
  }
</style>
